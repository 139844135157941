import React from 'react';

// import the react-json-view component
import ReactJson from 'react-json-view';

var footer = require("./json/footer.json")

var style = {
    fontSize: "x-large",
    padding: 30
}

function Footer() {
  return (
    <ReactJson
      src={footer} 
      theme={"monokai"}
      name={"Footer"}
      style={style}
      indentWidth={10}
      collapsed={2}
      displayDataTypes={false}
      displayObjectSize={false}
      enableClipboard={false}
    />
  );
}

export default Footer;
