import React from 'react';

// import the react-json-view component
import ReactJson from 'react-json-view';

var myCV = require("./json/myCV.json");

var style = {
  fontSize: "x-large",
  padding: 30
}

function App() {
  return (
    <ReactJson
      src={myCV} 
      theme={"monokai"}
      name={"Curriculum Vitae"}
      style={style}
      indentWidth={10}
      collapsed={true}
      displayDataTypes={false}
      displayObjectSize={false}
      enableClipboard={false}
    />
  );
}

export default App;
