import React from 'react';

// import the react-json-view component
import ReactJson from 'react-json-view';

var header = require("./json/header.json")

var style = {
    fontSize: "x-large",
    padding: 30
}

function Header() {
  return (
    <ReactJson
      src={header} 
      theme={"monokai"}
      name={"Header"}
      style={style}
      indentWidth={10}
      collapsed={2}
      displayDataTypes={false}
      displayObjectSize={false}
      enableClipboard={false}
    />
  );
}

export default Header;